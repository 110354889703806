define("qrpay-frontend/templates/merchant/pay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NHMOU/7C",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"vh-100 d-flex flex-column justify-content-center align-items-center \",[30,[36,0],[[32,0,[\"walletsLoaded\"]],\"wallets-loaded\"],null]]]],[12],[2,\"\\n\\t\"],[8,\"spinner\",[],[[],[]],null],[2,\"\\n\\t\"],[8,\"alert/title\",[],[[\"@title\"],[\"Loading...\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0,[\"walletsLoaded\"]],\"wallets-loaded\"],null]]]],[12],[2,\"\\n\\n\\t\"],[8,\"payment/header\",[],[[\"@back\",\"@isLoading\"],[[32,0,[\"back\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\\n\\t\"],[8,\"credit-card-i4go-iframe\",[],[[\"@i4go\",\"@onSuccess\",\"@onFailure\",\"@class\",\"@onWalletsLoaded\",\"@allowCardholderName\"],[[32,1,[\"i4go\"]],[32,0,[\"onSuccess\"]],[32,0,[\"onFailure\"]],[32,0,[\"iframeClass\"]],[30,[36,3],[[30,[36,2],[[32,0,[\"walletsLoaded\"]]],null]],null],true]],null],[2,\"\\n\\n\\t\"],[10,\"div\"],[15,0,[31,[[32,0,[\"wrapperClass\"]],\" d-flex flex-column justify-content-center align-items-center\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"spinner\",[],[[],[]],null],[2,\"\\n\\t\\t\\t\"],[8,\"alert/title\",[],[[\"@title\"],[\"Your payment is being processed\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"alert/payment-method\",[],[[],[]],null],[2,\"\\n\\t\\t\\t\"],[8,\"alert/title\",[],[[\"@title\"],[\"Choose payment method\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\n\\t\"],[8,\"check/buttons\",[],[[\"@pay\",\"@setPaymentMethod\",\"@buttonClass\"],[[32,0,[\"pay\"]],[32,0,[\"setPaymentMethod\"]],[32,0,[\"buttonClass\"]]]],null],[2,\"\\n\\n\\t\"],[8,\"footer-banner\",[],[[\"@isDisplayed\"],[[32,0,[\"displayFooterBanner\"]]]],null],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/merchant/pay.hbs"
    }
  });

  _exports.default = _default;
});